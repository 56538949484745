
// import libs
// import './libs/cookieBar';
import './components/livewireScrollToValidationError';

var $header = $('#header');
var headerHeight = $header.outerHeight();

// import components
import './components/ie-detection.js';
import './components/menu.js';
// import './components/modal.js';

// init
// $('.lazy').Lazy({
//     // your configuration goes here
//     scrollDirection: 'vertical',
//     effect: 'fadeIn',
//     effectTime: 150,
//     visibleOnly: true,
//     threshold: 500,
//     bind: 'event',
//     onError: function(element) {
//         console.log('error loading ' + element.data('src'));
//     }
// });

// ----------------------------------------
//  scroll to
var $scrollToItems = $('.scrollTo');
$.each($scrollToItems, function() {
    $(this).on('click', function(e) {
        e.preventDefault();

        var href = $(this).attr('href');
        if (typeof href === 'undefined') {
            href = $(this).attr('data-href');
        }
        var offset = $(this).attr('data-offset');

        var $target = $(href);
        if ($target.length) {
            $('html,body').animate({
                scrollTop: $target.offset().top
            }, {
                duration: 300,
                easing: 'swing'
            });
        }
    });
});
// ----------------------------------------

// scripts here
// ----------------------------------------

// Bootstrap dropdown
// $('.dropdown-toggle').dropdown();

// collapse
// $('.collapse__toggle, .collapse__toggle > label').on('click', function(e) {
//     $(this).closest('.collapse').toggleClass('show');
// });

// $('.page--detail').stickem({
//     offset: 100,
// });

// Filter tabs / all
$("#showAll").on("click", function() {
    $(this).addClass("active").parent("li").siblings().find("button").removeClass("active");
    $(".tab-pane").addClass("active").addClass("show");
});
$(".nav-link").not("#showAll").on("click", function() {
    console.log(this.hash);
    $(".tab-pane").not(this.hash).removeClass("active").removeClass("show");
});

// console.log(Livewire);
// select2 and livewire
function fireChangeLivewireEvent() {
    // console.log('on change');
    let livewire = $(this).attr('data-livewire');
    var livewireObject = eval(livewire);
    livewireObject.set($(this).attr('id'), $(this).val());
    //livewireObject.trigger('dom');
}

function initSelect2() {
    $('.select2').select2();
    $('.select2').on('change', fireChangeLivewireEvent);
}

$('.initSelect2OnChanged').on('change', function(e) {
    setTimeout(function() {
        initSelect2();
    }, 1000);
});

initSelect2();


// ScrollTop
$('.scrollTop').on('click', function(e) {
    var $target = $($(this).attr('data-href'));
    console.log($target);
    var position = 0;

    if ($target.length) {
        position = $target.offset().top;
    }

    $('html,body').animate({
        scrollTop: position
    }, {
        duration: 300,
        easing: 'swing'
    });

});

function initLightbox() {
    $('.lightbox').magnificPopup({
        gallery: {
          enabled: true
        },
        type: 'image',
        removalDelay: 500, //delay removal by X to allow out-animation
        callbacks: {
          beforeOpen: function() {
            // just a hack that adds mfp-anim class to markup
             this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
             this.st.mainClass = this.st.el.attr('data-effect');
          }
        },
        closeOnContentClick: true,
        midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
    });
}
initLightbox();

// $('.livewire-component').on('change', '.drag-area input[type=file]', function(e) {
//     setTimeout(function() {
//         initLightbox();
//     }, 3000);
// });

document.addEventListener("DOMContentLoaded", () => {
    Livewire.hook('message.processed', (message, component) => {
        // console.log(component.el);
        if ($(component.el).attr('id') == 'dokumenty') {
            initLightbox();
        }
    });
});

// test for position: sticky - zadny nadrizeny element nesmi mit overflow krom visible
// let parent = document.querySelector('.stickyContent').parentElement;
// while (parent) {
//   const hasOverflow = getComputedStyle(parent).overflow;
//   if (hasOverflow !== 'visible') {
//     console.log(hasOverflow, parent);
//   }
//   parent = parent.parentElement;
// }

// alert($('.stickyContent').find('thead > tr > *').length);
